import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _15860800 = () => interopDefault(import('../pages/config.ts' /* webpackChunkName: "pages/config" */))
const _d9827c4c = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _5f055687 = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _42231654 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _65794bf9 = () => interopDefault(import('../pages/reservation.vue' /* webpackChunkName: "pages/reservation" */))
const _444b0cee = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _4b3a12fb = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _8677bca0 = () => interopDefault(import('../pages/account/notification.vue' /* webpackChunkName: "pages/account/notification" */))
const _ea5f2484 = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _0671d9e0 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _be9e4d62 = () => interopDefault(import('../pages/reservations/history.vue' /* webpackChunkName: "pages/reservations/history" */))
const _3e3a62f3 = () => interopDefault(import('../pages/reservations/list.vue' /* webpackChunkName: "pages/reservations/list" */))
const _5076691a = () => interopDefault(import('../pages/service/contact.vue' /* webpackChunkName: "pages/service/contact" */))
const _f40400ba = () => interopDefault(import('../pages/service/coupon.vue' /* webpackChunkName: "pages/service/coupon" */))
const _9bfb6de0 = () => interopDefault(import('../pages/service/news.vue' /* webpackChunkName: "pages/service/news" */))
const _dd6e400a = () => interopDefault(import('../pages/service/notifications.vue' /* webpackChunkName: "pages/service/notifications" */))
const _2ef0d6db = () => interopDefault(import('../pages/service/privacy.vue' /* webpackChunkName: "pages/service/privacy" */))
const _0e5e4fcc = () => interopDefault(import('../pages/service/terms.vue' /* webpackChunkName: "pages/service/terms" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/config/",
    component: _15860800,
    pathToRegexpOptions: {"strict":true},
    name: "config"
  }, {
    path: "/forget_password/",
    component: _d9827c4c,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/inspire/",
    component: _5f055687,
    pathToRegexpOptions: {"strict":true},
    name: "inspire"
  }, {
    path: "/login/",
    component: _42231654,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reservation/",
    component: _65794bf9,
    pathToRegexpOptions: {"strict":true},
    name: "reservation"
  }, {
    path: "/reset_password/",
    component: _444b0cee,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/signup/",
    component: _4b3a12fb,
    pathToRegexpOptions: {"strict":true},
    name: "signup"
  }, {
    path: "/account/notification/",
    component: _8677bca0,
    pathToRegexpOptions: {"strict":true},
    name: "account-notification"
  }, {
    path: "/account/overview/",
    component: _ea5f2484,
    pathToRegexpOptions: {"strict":true},
    name: "account-overview"
  }, {
    path: "/account/password/",
    component: _0671d9e0,
    pathToRegexpOptions: {"strict":true},
    name: "account-password"
  }, {
    path: "/reservations/history/",
    component: _be9e4d62,
    pathToRegexpOptions: {"strict":true},
    name: "reservations-history"
  }, {
    path: "/reservations/list/",
    component: _3e3a62f3,
    pathToRegexpOptions: {"strict":true},
    name: "reservations-list"
  }, {
    path: "/service/contact/",
    component: _5076691a,
    pathToRegexpOptions: {"strict":true},
    name: "service-contact"
  }, {
    path: "/service/coupon/",
    component: _f40400ba,
    pathToRegexpOptions: {"strict":true},
    name: "service-coupon"
  }, {
    path: "/service/news/",
    component: _9bfb6de0,
    pathToRegexpOptions: {"strict":true},
    name: "service-news"
  }, {
    path: "/service/notifications/",
    component: _dd6e400a,
    pathToRegexpOptions: {"strict":true},
    name: "service-notifications"
  }, {
    path: "/service/privacy/",
    component: _2ef0d6db,
    pathToRegexpOptions: {"strict":true},
    name: "service-privacy"
  }, {
    path: "/service/terms/",
    component: _0e5e4fcc,
    pathToRegexpOptions: {"strict":true},
    name: "service-terms"
  }, {
    path: "/",
    redirect: "/reservation/",
    name: "index"
  }, {
    path: "*",
    redirect: "/reservation/",
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
