import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/messaging'

export const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyA2hEHq2G32SI80dvv-s_Akg_eQhwy2R2Q',
  authDomain: 'cti-ondemand-255210.firebaseapp.com',
  databaseURL: 'https://cti-ondemand-255210.firebaseio.com',
  projectId: 'cti-ondemand-255210',
  storageBucket: 'cti-ondemand-255210.appspot.com',
  messagingSenderId: '271897390108',
  appId: '1:271897390108:web:26828b86dfb0cec11a83d9'
})
export const firestore = firebaseApp.firestore()

const { Timestamp } = firebase.firestore
export { Timestamp }

// WebPush
export const webPushKey =
  'BKF1nRtZbZgGy9xErku1IMJkAVpXQm752pWmJC2WczR0sVYuNsgwXD0FeT1QbJJve58LJbqBCJWBkAn1O1HesoQ'

export async function getWebPushToken() {
  const messaging = firebaseApp.messaging()
  const token = await messaging.getToken({ vapidKey: webPushKey })
  return token
}

export async function delWebPushToken() {
  const messaging = firebaseApp.messaging()
  try {
    await messaging.deleteToken()
  } catch (err) {
    console.log(err)
    return false
  }
  return true
}
