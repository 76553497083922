export const getCenterCoordinates = () => {
  const center: any = {
    lat: 35.5446418,
    lng: 136.9075258
  }
  return center
}
export const getDefaultZoomLevel = () => {
  const zoom: number = 14
  return zoom
}
